
import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { REGISTRATION, FETCH_OFFICE_FUNCTION } from '../store/actions.type';
import { SET_REGISTRATION_FORM } from '@/store/mutations.type';
import { mapGetters } from 'vuex';
import Dropdown from '@/components/Dropdown.vue';
import ApiService from '@/common/api.service';

extend('required', required);

@Component({
  components: { ValidationProvider, ValidationObserver, Dropdown },
  computed: mapGetters([
    'isLoading', 'officeFunctionOptions', 'termsAccept',
    'regFormData',
  ]),
})

export default class RegistrationForm extends Vue {
  public termsAccept!: boolean;
  public showError: boolean = false;
  public showConfirm: boolean = false;
  public officeFunctionOptions!: any;
  private fieldOfActivityOptions = [];
  private regFormData!: any;
  private formData: any = {
    first_name: '',
    last_name: '',
    phone: '',
    inn: '',
    field_of_activity: '',
    office_function: '',
    company: '',
  };

  public async beforeMount() {
    if (!this.termsAccept) {
      this.$router.replace({ name: 'terms'});
    }
    const resp = await ApiService.query('/impact/field_of_activity');
    this.fieldOfActivityOptions = resp.data;
  }

  public async mounted() {
    await this.$store.dispatch(FETCH_OFFICE_FUNCTION);
  }

  get officeFunction() {
    let options: any = [];
    if (this.officeFunctionOptions) {
      options = options.concat(this.officeFunctionOptions.map((func: any) => {
        return { value: func.id, text: func[`name_${this.$i18n.locale}`] };
      }));
    }
    return options;
  }

  get fieldOfActivityOptionsF() {
    let options: any = [];
    if (this.fieldOfActivityOptions) {
      options = options.concat(this.fieldOfActivityOptions.map((func: any) => {
        return { value: func.id, text: func[`name_${this.$i18n.locale}`] };
      }));
    }
    return options;
  }

  public async onSubmit() {
    this.$store.commit(SET_REGISTRATION_FORM, this.formData);
    const res = await this.$store.dispatch(REGISTRATION, this.regFormData);
    if (res) {
      this.showConfirm = true;
    } else {
      this.showError = true;
    }
  }
}

