
  import { Vue, Component } from 'vue-property-decorator';
  import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
  import { ACCEPT_TERMS } from '@/store/mutations.type';
  import vuescroll from 'vuescroll';

  @Component({
    components: { vuescroll, PrivacyPolicy },
  })
  export default class TermsPage extends Vue {
    private scrollOptions = {
      rail: {
        opacity: '0.1',
        background: '#000000',
        size: '4px',
        gutterOfSide: '0',
        gutterOfEnds: '0',
      },
      bar: {
        background: '#FF2D55',
        keepShow: true,
        specifyBorderRadius: '4px',
        size: '4px',
        minSize: 0.1,
      },
    };

    private acceptTerms() {
      this.$store.commit(ACCEPT_TERMS);
      this.$router.push({ name: 'registrationPre' });
    }
  }
