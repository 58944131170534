
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import TextInfo from '@/components/TextInfo.vue';
    import { FETCH_NOTIFICATIONS, FETCH_FILE } from '../store/actions.type';
    import { mapGetters } from 'vuex';
    import { convertDate } from '@/common/helpers';

    @Component({
        components: {TextInfo},
        computed: mapGetters(['notifications', 'notificationsCount', 'isLoading']),
    })
    export default class NotificationPage extends Vue {

        public notifications!: object[];
        public notificationsCount!: number;
        public showFullText: any = {};
        public perPage: number = 10;
        public pageParams: {
            page: number,
            sortBy: string,
            sortDesc: boolean,
        } = {
            page: 1,
            sortBy: 'incident_date',
            sortDesc: true,
        };

        @Watch('$route', { immediate: true, deep: true })
        public async onUrlChange(newVal: any) {
            if (this.pageParams.page !== newVal.params.page) {
                this.pageParams.page = newVal.params.page;
                await this.fetchNotifications();
            }
        }

        public linkGen(pageNum: any) {
            return { path: `/notification/page/${pageNum}` };
        }

        public getFile(url: string) {
            this.$store.dispatch(FETCH_FILE, url);
        }

        get numberOfPages(): number {
            const division = this.notificationsCount / this.perPage;
            const rounded = Math.floor(division);
            return rounded + (division === rounded && this.notificationsCount !== 0 ? 0 : 1);
        }

        get formattedNotifications() {
            return this.notifications.map( ( notification: any ) => {
                const tmp: any = {...notification};
                tmp.when = convertDate(notification.when);
                tmp.attachment = tmp.attachment ?
                    {link: `/impact/notification/${tmp.id}/get_file`,
                        title: tmp.attachment.split('/').slice(-1)[0]} : null;
                tmp.body = this.$i18n.locale === 'en' && tmp.body_en ? tmp.body_en : tmp.body;
                tmp.subject = this.$i18n.locale === 'en' && tmp.subject_en ? tmp.subject_en : tmp.subject;
                return tmp;
            });
        }

        private async mounted() {
            await this.fetchNotifications();
        }

        private async fetchNotifications() {
            await this.$store.dispatch(FETCH_NOTIFICATIONS, {page: this.pageParams.page, page_size: this.perPage});
        }
    }
