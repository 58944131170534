
import { Vue, Component } from 'vue-property-decorator';
import ApiService from '@/common/api.service';

@Component
export default class SettingsSecurity extends Vue {

  private email = '';
  private newEmail = '';
  private oldPassword = '';
  private newPassword = '';
  private newEmailEmpty = false;
  private oldPasswordEmpty = false;
  private newPasswordEmpty = false;
  private oldPasswordFieldType = 'password';
  private newPasswordFieldType = 'password';

  private async mounted() {
    const resp: any = await ApiService.query('/impact/user/current');
    this.email = resp.data.email;
  }

  private async saveEmail() {
    if (!this.newEmail.length) {
      this.newEmailEmpty = true;
      return;
    }
    await ApiService.post('/impact/user/change_email', {email: this.newEmail});
    this.newEmailEmpty = false;
    alert(this.$i18n.t('emailChanged'));
  }

  private async savePassword() {
    this.oldPasswordEmpty = false;
    this.newPasswordEmpty = false;
    if (!this.oldPassword.length) {
      this.oldPasswordEmpty = true;
    }
    if (!this.newPassword.length) {
      this.newPasswordEmpty = true;
    }
    if (this.oldPasswordEmpty || this.newPasswordEmpty) {
      return;
    }
    try {
      await ApiService.post('/impact/user/change_password',
          {old_password: this.oldPassword, password: this.newPassword});
    } catch (e) {
      alert(this.$i18n.t('strings.currentPasswordWrong'));
      this.oldPassword = '';
      this.newPassword = '';
      return;
    }
    this.oldPasswordEmpty = false;
    this.newPasswordEmpty = false;
    this.oldPassword = '';
    this.newPassword = '';
    alert(this.$i18n.t('strings.passwordChanged1'));
  }

  private toggleOldPasswordVisibility() {
    this.oldPasswordFieldType = this.oldPasswordFieldType === 'password' ? 'text' : 'password';
  }

  private toggleNewPasswordVisibility() {
    this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password';
  }
}
