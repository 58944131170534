
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { SEND_PASSWORD_RECOVERY } from '@/store/actions.type';

extend('required', required);

@Component({
    components: { ValidationProvider, ValidationObserver },
})
export default class PasswordRecoveryPage extends Vue {
  private forgotFormSent: boolean = false;

  private recoveryEmail: string = '';

  private emailError: boolean = false;

  private async sendEmail() {
    this.emailError = false;
    if (this.recoveryEmail.length) {
      await this.$store.dispatch(SEND_PASSWORD_RECOVERY, this.recoveryEmail);
      this.$router.push({name: 'login'});
    } else {
      this.emailError = true;
    }
  }
}
