
import {Vue, Component} from 'vue-property-decorator';
import ApiService from '@/common/api.service';

@Component
export default class SettingsNotify extends Vue {

  private subscribed = true;
  private formEnabled = true;

  private async mounted() {
    const resp: any = await ApiService.query('/impact/user/current');
    if ('subscribed' in resp.data) {
      this.formEnabled = true;
      this.subscribed = resp.data.subscribed;
    } else {
      this.formEnabled = false;
      this.subscribed = false;
    }
  }

  private toggleSubscription() {
    if (this.formEnabled) {
      this.subscribed = !this.subscribed;
    }
  }

  private async saveForm() {
    if (this.formEnabled) {
      await ApiService.post('/impact/user/change_notifications',
          {subscribed: this.subscribed});
    }
  }
}
