
  import { Component, Vue } from 'vue-property-decorator';
  import TextInfo from '@/components/TextInfo.vue';
  import { FETCH_INFO_TEXT } from '@/store/actions.type';
  import ApiService from '@/common/api.service';
  import { convertDate } from '@/common/helpers';
  import { getLocale } from '@/common/locale.service';

  @Component({
    components: {TextInfo},
  })
  export default class MainPage extends Vue {

    public textMsg: string = '';
    public newsToDisplay: any = [];
    private news: any = [];
    private showVideoVetting = false;
    private showVideoAnalysis = false;
    private showVideoPrudence = false;

    public pageNews(pageNumber: number) {
      const children = this.$el.querySelectorAll('.c--dashboard-widget__dots-dot-news');
      for (let i = 0; i < children.length; i++) {
        if (i === pageNumber) {
          children[i].classList.add('active');
        } else {
          children[i].classList.remove('active');
        }
      }
      this.newsToDisplay = this.news.slice(pageNumber * 3, pageNumber * 3 + 3);
    }

    public openVideoVetting() {
      this.showVideoVetting = true;
    }

    public openVideoAnalysis() {
      this.showVideoAnalysis = true;
    }

    public openVideoPrudence() {
      this.showVideoPrudence = true;
    }

    private async mounted() {
      this.textMsg = await this.$store.dispatch(FETCH_INFO_TEXT, 1);
      const resp: any = await ApiService.query('impact/news');
      this.news = resp.data.results.map((item: any) => {
        item.news_time = convertDate(item.published);
        if (getLocale() === 'en' && item.subject_en) {
          item.subject = item.subject_en;
        }
        return item;
      });
      this.newsToDisplay = this.news.slice(0, 3);
    }
  }
