<template>
  <div class="page page--profile page--error">
    <div class="page--error__title">Раздел находится в разработке</div>
    <div class="page--error__subtitle">
      Приносим свои извинения. С уважением, администрация сайта.
    </div>
    <router-link to="/" class="a--button v--size-1 v--type-1">
      <div class="a--button__link">на главную</div>
    </router-link>
  </div>

</template>

<script>
export default {
name: 'StubPage',
};
</script>

<style scoped>

</style>
