
import {Vue, Component} from 'vue-property-decorator';
import SettingsProfile from '@/components/Settings/SettingsProfile.vue';
import SettingsNotify from '@/components/Settings/SettingsNotify.vue';
import SettingsUsers from '@/components/Settings/SettingsUsers.vue';
import SettingsSecurity from '@/components/Settings/SettingsSecurity.vue';
import {mapGetters} from 'vuex';
import {PURGE_AUTH} from '@/store/mutations.type';

@Component({
  components: {SettingsProfile, SettingsNotify, SettingsUsers, SettingsSecurity},
  computed: mapGetters(['currentUser']),
})
export default class SettingsPage extends Vue {
  private currentUser!: any;

  private showConfirm() {
    const msg: any = this.$t('strings.logoutMsgConfirm');
    if (confirm(msg)) {
      this.$store.commit(PURGE_AUTH);
      this.$router.push({name: 'login'});
    }
  }
}
