
  import { Component, Vue } from 'vue-property-decorator';
  import ApiService from '@/common/api.service';
  import { convertDate } from '@/common/helpers';
  import {FETCH_FILE} from '@/store/actions.type';
  import { getLocale } from '@/common/locale.service';

  @Component
  export default class NewsPage extends Vue {
    public news: any = {published: '', title: '', title_en: '', body: '', body_en: '', id: 0, fieldImage: null};

    public getFile(url: string) {
      this.$store.dispatch(FETCH_FILE, url);
    }

    private async mounted() {
      const resp: any = await ApiService.query('impact/news/' + this.$route.params.id);
      this.news.id = resp.data.id;
      this.news.published = convertDate(resp.data.published);
      this.news.fieldImage = resp.data.field_image;
      if (getLocale() === 'en' && resp.data.title_en) {
        this.news.title = resp.data.title_en;
        this.news.body = resp.data.body_en.replace('\n', '<br />');
      } else {
        this.news.title = resp.data.title;
        this.news.body = resp.data.body.replace('\n', '<br />');
      }
    }
  }
