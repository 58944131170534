
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { ACCEPT_TERMS, REFUSE_TERMS, SET_REGISTRATION_FORM } from '@/store/mutations.type';
import { mapState, mapGetters } from 'vuex';


extend('required', required);

@Component({
  components: { ValidationProvider, ValidationObserver },
  computed: mapGetters(['isLoading', 'errors', 'isAuthenticated', 'regFormData', 'termsAccept']),
})
export default class RegistrationPre extends Vue {

  private regFormData!: any;
  private termsAccept!: boolean;
  private formData: any = {
    email: '',
  };

  private acceptTerms() {
    if (!this.termsAccept) {
      this.$store.commit(ACCEPT_TERMS);
    } else {
      this.$store.commit(REFUSE_TERMS);
    }
  }

  private onSubmit() {
    if (this.termsAccept) {
      this.$store.commit(SET_REGISTRATION_FORM, this.formData);
      this.$router.push({name: 'registration'});
    }
  }
}

