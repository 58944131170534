
/* tslint:disable:no-console */
import {Vue, Component, Watch} from 'vue-property-decorator';
import FormInput from '@/components/Verification/FormInput.vue';
import {mapGetters} from 'vuex';
import {FETCH_ATI_SU_ID, FETCH_PRUDENCE_VERIFICATION} from '@/store/actions.type';
import {numberWithCommas} from '@/common/helpers';
import ApiService from '@/common/api.service';
import PopUp from '@/components/PopUp.vue';
import AtiSUInfo from '@/components/Verification/AtiSUInfo.vue';
import INNInfo from '@/components/Verification/INNInfo.vue';

@Component({
  components: {PopUp, FormInput, AtiSUInfo, INNInfo},
  computed: mapGetters(['prudenceDataRaw', 'currentUser', 'errors', 'riskIsLoading']),
})
export default class CommercialPrudence extends Vue {
  private currentUser!: any;
  private errors!: any;
  private prudenceDataRaw!: any[];
  private riskIsLoading!: any;
  private inn: string = '';
  private ogrn: string = '';
  private externalId: string = '';
  private formSubmitted: boolean = false;
  private showThirdParty: number | null = null;
  private showThirdPartyAti: number | null = null;
  private showCustomers: number | null = null;
  private showCustomersAti: number | null = null;
  private currentCase: number = 0;
  private formFillProgress: number = 0;
  private checks: number = 0;
  private limitSpent: boolean = false;
  private activeItems: any = {};
  private modalAtiSuId: number = 0;
  private modalCompanyCode: number = 0;
  private fromAdminView: boolean = false;
  private prudenceDataAdminNew: any = [];
  private prudenceDataAdminOld: any = [];
  private prudenceDataAdmin: any = [];

  private beforeMount() {
    if (window.localStorage.getItem('prudenceAdmin')) {
      this.fromAdminView = true;
    }
  }

  private async mounted() {
    if (this.fromAdminView) {
      if (window.localStorage.getItem('innNewMatches')) {
        let resp: any = await ApiService.post('/impact/verification/prudence', { inn: window.localStorage.getItem('innNewMatches')!.split(','), match_ati_su_id: true });
        this.prudenceDataAdminNew = resp.data.data;
      }
      if (window.localStorage.getItem('innOldMatches')) {
        let resp: any = await ApiService.post('/impact/verification/prudence', { inn: window.localStorage.getItem('innOldMatches')!.split(','), match_ati_su_id: true });
        this.prudenceDataAdminOld = resp.data.data;
      }
      if (window.localStorage.getItem('innMatches')) {
        let resp: any = await ApiService.post('/impact/verification/prudence', { inn: window.localStorage.getItem('innMatches')!.split(','), match_ati_su_id: true });
        this.prudenceDataAdmin = resp.data.data;
      }
      window.localStorage.removeItem('innNewMatches');
      window.localStorage.removeItem('innOldMatches');
      window.localStorage.removeItem('innMatches');
      window.localStorage.removeItem('prudenceAdmin');
    }
  }

  private externalIdFormat(value: string) {
    return value.replace(/[^0-9]/g, '');
  }

  private async onSubmit() {
    if (this.formFillProgress === 0) {
      return;
    }
    this.activeItems = {};
    this.formSubmitted = true;
    this.currentCase = 0;
    await this.$store.dispatch(FETCH_PRUDENCE_VERIFICATION,
        {inn: this.inn, ogrn: this.ogrn, externalId: this.externalId});
    if (this.errors && this.errors.limitSpent) {
      this.limitSpent = true;
    } else {
      this.checks = this.checks + 1;
    }
  }

  private clearFormFields() {
    this.externalId = '';
    this.inn = '';
    this.ogrn = '';
    this.formSubmitted = false;
    this.currentCase = 0;
  }

  @Watch('inn')
  @Watch('ogrn')
  @Watch('externalId')
  private handleFormFill() {
    if (this.inn.length === 12 || this.inn.length === 10 || this.ogrn.length === 13 ||
        this.ogrn.length === 15 || this.externalId.length) {
      this.formFillProgress = 1;
    } else {
      this.formFillProgress = 0;
    }
  }

  private numberWithCommas(val: number) {
    return numberWithCommas(val);
  }

  private async onSubmitAtiSU() {
    try {
      const resp: any = await ApiService.query('/impact/verification/find_ati_su_id', {inn: this.inn});
      if (resp.data.ati_su_id) {
        this.externalId = resp.data.ati_su_id;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  private switchActiveState(key: number) {
    const tmp = {...this.activeItems};
    if (this.activeItems[key]) {
      delete tmp[key];
    } else {
      tmp[key] = true;
    }
    this.activeItems = tmp;
  }

  get currentCaseData() {
    let caseNum = null;
    if (this.showThirdParty !== null) {
      caseNum = this.showThirdParty;
    }
    if (this.showThirdPartyAti !== null) {
      caseNum = this.showThirdPartyAti;
    }
    if (this.showCustomers !== null) {
      caseNum = this.showCustomers;
    }
    if (this.showCustomersAti !== null) {
      caseNum = this.showCustomersAti;
    }
    if (caseNum === null) {
      return null;
    }
    return this.prudenceData[caseNum] ? {...this.prudenceData[caseNum]} : null;
  }

  get prudenceData() {
    let data = [];
    if (this.fromAdminView) {
      data = [...this.prudenceDataAdminNew.map((item: any) => ({ ...item, new: true })), ...this.prudenceDataAdminOld.map((item: any) => ({ ...item, old: true })), ...this.prudenceDataAdmin];
    } else {
      data = this.prudenceDataRaw;
    }
    return data.map((item: any) => {
      return {
        ...item,
        amount: !(item.amount === null || item.amount === undefined) ? 
          `${numberWithCommas(item.amount)} рублей` : '',
      };
    })
  }

  get prudenceLimitLeft() {
    return this.currentUser.commercialPrudenceLimit - this.checks;
  }
}
