
    import { Component, Vue } from 'vue-property-decorator';
    import PasswordChangeForm from '@/components/Login/PasswordChangeForm.vue';
    import { RESET_PASSWORD } from '@/store/actions.type';
    import { mapGetters } from 'vuex';
    import { SET_NOTIFY } from '@/store/mutations.type';


    @Component({
        components: { PasswordChangeForm },
        computed: mapGetters(['errors']),
    })
    export default class PasswordResetPage extends Vue {

        public errors!: any;
        public resetKey: string = '';
        public passwordFormMessage: any = [];
        public password1: string = '';
        public password2: string = '';
        public pw1Type: string = 'password';
        public pw2Type: string = 'password';

        public mounted() {
            const urlQuery: any = this.$route.query;
            this.resetKey = urlQuery.key;
            if (!this.resetKey) {
                this.$router.push('/404');
            }
        }

        public async submitPassword() {
            this.passwordFormMessage = [];
            if (!this.password1.length) {
                this.passwordFormMessage.push('pw1');
            }
            if (!this.password2.length) {
                this.passwordFormMessage.push('pw2');
            }
            if (this.passwordFormMessage.length) {
                return;
            }
            if (this.password1 !== this.password2) {
              this.passwordFormMessage.push('notMatch');
              return;
            }
            await this.$store.dispatch(RESET_PASSWORD, {password: this.password1, key: this.resetKey});
            this.$store.commit(SET_NOTIFY, 'passwordChanged2');
            this.$router.push({ name: 'login' });
        }

        private switchPasswordFieldType(fieldType: any) {
            switch (fieldType) {
                case 'pw1':
                    this.pw1Type = this.pw1Type === 'password' ? 'text' : 'password';
                    break;
                default:
                    this.pw2Type = this.pw2Type === 'password' ? 'text' : 'password';
            }
        }
    }
