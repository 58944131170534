
import {Vue, Component, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {
  FETCH_USERS, CREATE_USER, FETCH_OFFICE_FUNCTION, DELETE_USER, SEND_NOTIFICATION, FETCH_SEARCH_USER,
} from '@/store/actions.type';
import {convertDate, pwdGen} from '@/common/helpers';

@Component({
  computed: mapGetters(['users', 'usersCount', 'isLoading', 'officeFunctionOptions', 'searchUsers', 'errors']),
})


export default class SettingsUsers extends Vue {

  public users!: object[];
  public usersCount!: number;
  public userAccessLevel: number = 0;
  public officeFunctionOptions!: object[];
  public perPage: number = 20;
  public pageParams: {
    page: number,
    sortBy: string,
    sortDesc: boolean,
  } = {
    page: 1,
    sortBy: 'id',
    sortDesc: true,
  };


  private async mounted() {
    // await this.$store.dispatch(FETCH_USERS);
    await this.$store.dispatch(FETCH_OFFICE_FUNCTION);
    await this.getUsers();
    // await this.$store.dispatch(FETCH_SEARCH_USER);
  }


  get numberOfPages(): number {
    return Math.floor(this.usersCount / this.perPage) + 1;
  }

  get formattedUsers() {
    return this.users.map((item: any, index: number) => {
      const officeFunction: any = item.office_function ?
          this.officeFunctionOptions.filter((opt: any) => opt.id === item.office_function) : [];
      return {
        index: (this.pageParams.page - 1) * this.perPage + index + 1,
        id: item.id,
        registrationDate: convertDate(item.registration_date),
        ip_address: item.ip_address,
        name: `${item.first_name} ${item.last_name}`,
        email: item.email,
        phone: item.phone,
        company: item.company,
        officeFunction: officeFunction.length ? officeFunction[0][`name_${this.$i18n.locale}`] : '',
        user: item.user,
        verificationCount: item.verification_count,
      };
    });
  }

  private async getUsers(page: number = 1) {
    const params: any = {
      page,
      ordering: (this.pageParams.sortDesc ? '-' : '') + this.pageParams.sortBy,
    };
    if (this.userAccessLevel) {
      params.group_id = this.userAccessLevel;
    }
    await this.$store.dispatch(FETCH_USERS, params);
  }


}
