
import { Vue, Component } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import ApiService from '@/common/api.service';
import Dropdown from '@/components/Dropdown.vue';
import { mapGetters } from 'vuex';
import {FETCH_OFFICE_FUNCTION, REGISTRATION} from '@/store/actions.type';
import { required } from 'vee-validate/dist/rules';

extend('required', required);

@Component({
  components: { ValidationProvider, ValidationObserver, Dropdown },
    computed: mapGetters([ 'officeFunctionOptions' ]),
})
export default class SettingsProfile extends Vue {

  private fieldOfActivityOptions = [];
  private officeFunctionOptions!: any;
  private formData: {
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    office_function: number | string,
    field_of_activity: number | string,
    company: string,
  } = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    office_function: '',
    field_of_activity: '',
    company: '',
  };
  private showError: boolean = false;
  private profileId = 0;

  private async beforeMount() {
    const resp = await ApiService.query('/impact/field_of_activity');
    this.fieldOfActivityOptions = resp.data;
    await this.$store.dispatch(FETCH_OFFICE_FUNCTION);
    const resp2 = await ApiService.query('/impact/profile/current');
    this.formData.first_name = resp2.data.first_name;
    this.formData.last_name = resp2.data.last_name;
    this.formData.phone = resp2.data.phone;
    this.formData.email = resp2.data.email;
    this.formData.office_function = resp2.data.office_function;
    this.formData.field_of_activity = resp2.data.field_of_activity;
    this.formData.company = resp2.data.company;
    this.profileId = resp2.data.id;
  }

  get fieldOfActivityOptionsF() {
    let options: any = [];
    if (this.fieldOfActivityOptions) {
      options = options.concat(this.fieldOfActivityOptions.map((func: any) => {
        return { value: func.id, text: func[`name_${this.$i18n.locale}`] };
      }));
    }
    return options;
  }

  get officeFunction() {
    let options: any = [];
    if (this.officeFunctionOptions) {
      options = options.concat(this.officeFunctionOptions.map((func: any) => {
        return { value: func.id, text: func[`name_${this.$i18n.locale}`] };
      }));
    }
    return options;
  }

  private async onSubmit() {
    await ApiService.put('/impact/profile/' + this.profileId, this.formData);
    alert('Профиль сохранён!');
  }
}
