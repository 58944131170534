
import {Component, Prop, Vue} from 'vue-property-decorator';
import ApiService from '@/common/api.service';
import { convertDateToDots } from '@/common/helpers';

@Component({})
export default class INNInfo extends Vue {
  @Prop({default: 0})
  private companyCode!: number;
  private companyInfo: any = null;
  private loaded: boolean = false;

  private async mounted() {
    this.companyInfo = null;
    this.loaded = false;
    const resp = await ApiService.query('/impact/verification/inn_check',
        {code: this.companyCode});
    if (resp.data.items && resp.data.items.length) {
      if (resp.data.items[0].ЮЛ) {
        this.companyInfo = resp.data.items[0].ЮЛ;
      } else if (resp.data.items[0].ИП) {
        this.companyInfo = resp.data.items[0].ИП;
      }
    }
    this.loaded = true;
  }

  private closeINNInfo() {
    this.$emit('exit', true);
  }

  private formatDate(s: string) {
    if (s && s.length) {
      return convertDateToDots(s);
    } else {
      return '';
    }
  }
}
