
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { LOGIN, FETCH_USER_INFO, SEND_PASSWORD_RECOVERY } from '@/store/actions.type';
import { mapState, mapGetters } from 'vuex';
import { CLEAR_NOTIFY } from '@/store/mutations.type';


extend('required', required);

@Component({
  components: { ValidationProvider, ValidationObserver },
  computed: mapGetters(['isLoading', 'errors', 'isAuthenticated']),
})
export default class LoginPage extends Vue {

  private isAuthenticated!: boolean;

  private isLoading!: boolean;

  private showError: boolean = false;

  private formData: {
    username: string,
    password: string,
  } = {
    username: '',
    password: '',
  };

  private passwordFieldType: string = 'password';

  private switchPasswordFieldType() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  private async onSubmit() {
    const res = await this.$store.dispatch(LOGIN, this.formData);
    this.$store.commit(CLEAR_NOTIFY);
    if (this.isAuthenticated) {
      // await this.$store.dispatch(FETCH_USER_INFO);
      this.$router.push({name: 'mainPage'});
    } else {
      this.showError = true;
    }
  }
}

